import React from 'react'
import { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NavbarDoctor from '../../Components/Doctor/NavbarDoctor'
import Header from '../../Components/Doctor/Header'
import { Axios } from '../../Axios/doctor';
import { useSelector } from 'react-redux';


function Slots() {
    const [slots, setSlots] = useState(null)
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [updateUI, setUpdateUI] = useState(false)

    const doctorInfo = useSelector((state) => state.doctor.doctor)
    const doctorId = doctorInfo._id

    useEffect(() => {
        Axios.get(`/getSlots/${doctorId}`).then((response) => {
            if (response.data) {
                setSlots(response.data.slot)
            }
        }).catch((error) => {
            console.log("error", error)
        })
    }, [doctorId, updateUI])

    const handleAddSlot = async () => {
        Axios.post('/add_slot', { selectedDate, doctorId }).then((response) => {
            if (response.data) {
                setUpdateUI(prev => !prev)
            }
        }).catch((error) => {
            alert(error.response.data.message);
        })
    }

    return (
        <div >
            <NavbarDoctor />
            <Header title='slots' />

            <div className='mt-6  flex justify-center' >
                <DatePicker
                    showIcon
                    toggleCalendarOnIconClick
                    selected={selectedDate}
                    minDate={new Date()}
                    minTime={selectedDate && selectedDate.getDate() === new Date().getDate() ? new Date().setMinutes(new Date().getMinutes() + 1) : new Date().setHours(0, 0, 0)}
                    maxTime={selectedDate && selectedDate.getDate() === new Date().getDate() ? new Date().setHours(23, 59, 59) : new Date().setHours(23, 59, 59)}
                    showTimeSelect
                    onChange={(date) => setSelectedDate(date)}
                    withPortal
                    dateFormat="MMMM d, yyyy h:mm aa"
                    className='border border-black hover:cursor-pointer '
                />
                <button className='bg-[#6E4975] font-inder text-white  rounded-md px-4 ms-2' onClick={handleAddSlot}>Add Slot</button>
            </div>

            <div className='flex justify-center '>
                {slots && slots.length > 0 ? (
                    <div className=' w-auto grid grid-cols-2 gap-1 sm:gap-2 sm:grid-cols-3 justify-center  mt-10' >
                        {
                            slots.map((item, index) => (
                                <div key={index} className="div bg-[#95B4E0] lg:w-auto mx-1 sm:mx-4  px-2 py-1 rounded-md">
                                    {new Date(item.date).toLocaleString('en-IN', {
                                        timeZone: 'Asia/Kolkata',
                                        weekday: 'long', // to display the day
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        second: 'numeric',
                                    })}
                                </div>
                            ))
                        }
                    </div>
                ) : (
                    <div className="div">
                        <div className="text-center font-medium mt-10" >
                            You have no slots added yet...
                        </div>

                        <div className='h-96'>
                            <img src="/12810345_5070757.jpg" className="w-full h-full object-cover" alt="empty slot" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Slots
